import { useContext } from "react";
import { fetchAndParseData } from "../utilities/fetchAndParse";
import { apiUrl } from "../context/AppContext";
import { AuthContext } from "../context/AuthContext";
import { BatteriesContext } from "../context/BatteriesContext";
import { AddersContext } from "../context/AddersContext";
import { FinancialContext } from "../context/FinancialContext";
import { ProposalContext } from "../context/ProposalContext";

/* Custom Hook to get the proposal and extra data to save in the global states */

const useProposal = () => {
  /* Global states */
  const { token, setToken } = useContext(AuthContext);
  const {
    setProposalData,
    projectId,
    setModuleId,
    setInverterId,
    setValue,
    setInformation,
    setTotalEstimatedSaving,
    setError,
    setIsFetching,
    setSavingsAnnual,
    setOverrideAndProfit,
    setBasePricePerWatt,
  } = useContext(ProposalContext);
  const { setAdders } = useContext(AddersContext);
  const {
    setBatteries,
    setSelectedBatteries,
    setSelectedBattery,
    setConsumptionsProfiles,
    setProfileSelected,
    setMonthsConsumptions,
    setMonthlySavings,
    setMcSettings,
    setTariffs,
    setSelectedTariff,
  } = useContext(BatteriesContext);
  const {
    setSelectedBank,
    setPaymentOptions,
    setSelectedPaymentOption,
    setTaxCredit,
    setTaxCreditValue,
  } = useContext(FinancialContext);

  /* Obtaining the token */
  const getToken = () => {
    if (!projectId) return;

    const tokenConfig = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    fetch(`${apiUrl}/auth?lead_id=${projectId}`, tokenConfig)
      .then((res) => res.json())
      .then((token) => setToken(token.Token))
      .catch((err) => {
        console.log("Error to get token: ", err);
        setError(`Error to get token: ${err}`);
      });
  };

  /* Obtaining the proposal */
  const getProposal = () => {
    /* If there is no token, there is no proposal and show the error */
    if (!token) return;

    const config = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Cache-Control": "no-cache",
      },
    };

    /* Backend endpoints */
    const getProposal = fetchAndParseData(
      `${apiUrl}/project/proposal/`,
      config
    );
    const getBatteries = fetchAndParseData(`${apiUrl}/items/batteries`, config);
    const getAdders = fetchAndParseData(`${apiUrl}/items/adders`, config);
    const getModules = fetchAndParseData(`${apiUrl}/items/modules`, config);
    const getInverters = fetchAndParseData(`${apiUrl}/items/inverters`, config);
    const getFinancials = fetchAndParseData(
      `${apiUrl}/items/financial`,
      config
    );
    const getSystemInfo = fetchAndParseData(`${apiUrl}/systeminfo`, config);
    const getProfiles = fetchAndParseData(
      `${apiUrl}/consumptionProfile?myCustoms=true`,
      config
    );
    const getTariffs = fetchAndParseData(`${apiUrl}/tariffs`,config);

    /* Promise.all to get all the data at the same time */
    Promise.all([
      getProposal,
      getBatteries,
      getAdders,
      getModules,
      getInverters,
      getFinancials,
      getSystemInfo,
      getProfiles,
      getTariffs,
    ])
      .then((results) => {
        const [
          proposal,
          batteries,
          adders,
          modules,
          inverters,
          financials,
          systemInfo,
          profiles,
          tariffs
        ] = results;

        const { associatedBatteries, batteriesList } = batteries;
        console.log("Adders",adders);


        if (proposal.error) throw Error(proposal.error);
        setBasePricePerWatt(proposal?.project?.basePricePerWatt);

        /* get the small system adder index */
        const smallSystemIndex = adders.findIndex(
          (adder) => adder.id === "ADDE-0020"
        );

        /* If the system capacity is less than 4kW, the small system adder is checked obligatorily */
        if (proposal.systemCapacity < 4)
          adders[smallSystemIndex].isChecked = true;

        /* Set the proposal data */
        if (proposal.project.creditAmount) setTaxCredit((prev) => !prev);
        setProposalData({
          ...proposal,
          batteries: [...batteriesList],
          associatedBatteries: [...associatedBatteries],
          modules: [...modules],
          inverters: [...inverters],
          financials: { ...financials },
          systemSizeInfo: [...systemInfo],
        });

        const overridePricePerWatt = proposal.project.overridePricePerWatt;

        setInformation((prev) => ({
          ...prev,
          federalTaxCredit: proposal.project.taxCredit,
          creditAmount: proposal.project.creditAmount,
          overridePricePerWatt: overridePricePerWatt,
          escalationRate: proposal.project.escalationRate,
          dcToAcRatio: proposal.project.dcToAcRatio,
        }));

        let calcProfit =
          overridePricePerWatt - proposal.project.basePricePerWatt;
        calcProfit = parseFloat(calcProfit.toFixed(2));
        calcProfit = calcProfit * proposal.realSystemCapacity * 1000;
        calcProfit = Number(calcProfit.toFixed(2));

        setOverrideAndProfit({
          override: overridePricePerWatt,
          profit: calcProfit,
        });

        setAdders(adders);
        setModuleId(proposal.project.solarPanel || "");
        setInverterId(proposal.project.inverter || "");

        /******** FINANCIALS ********/

        /* get the bank name */
        const bankName = financials.selectedFinancial.bankName;
        /* Set the selected bank and the payment options */
        setSelectedBank(bankName);
        setPaymentOptions(financials[bankName]);
        setSelectedPaymentOption(financials.selectedFinancial);

        /******** BATTERIES ********/

        let selectorOfBatteries = [];
        /* If there is no associated batteries, set the selector of batteries with 0, else set the selector of batteries with the qty of the associated batteries */
        if (!associatedBatteries || !associatedBatteries.length) {
          selectorOfBatteries = batteriesList.map(() => 0);
          setBatteries(batteriesList);
        } else {
          let batteries = batteriesList.map((battery, index) => {
            let associatedBattery = associatedBatteries.find(
              (associated) => associated.id === battery.id
            );
            let qty = associatedBattery ? associatedBattery.qty : 0;
            selectorOfBatteries.push(qty);
            return { ...battery, qty };
          });
          setBatteries(batteries);
        }
        setSelectedBatteries(selectorOfBatteries);

        /******** COST OF THE PROPOSAL ********/

        /* Calculate the total estimated cost of batteries and the total cost of the proposal */
        let totalCost = 0;
        for (let i = 0; i < batteriesList.length; i++) {
          totalCost += batteriesList[i].price * selectorOfBatteries[i];
        }

        let proposalValue = totalCost;

        adders.forEach((adder) => {
          if (!adder.isChecked) return;
          if (adder.measurementUnit && adder.qty) {
            let rounded = Math.ceil(parseInt(adder.qty));
            let calc = rounded * adder.price;
            adder.totalPrice = calc;
            proposalValue += calc;
          } else {
            proposalValue += adder.price;
          }
        });

        setTaxCreditValue(proposalValue); // Check the 0.3 value

        /* Sets */
        const selectedTariffFound = tariffs.tariffs.find(tariff => tariff.id === tariffs.associatedTariff);
        setTariffs(tariffs.tariffs);
        setSelectedTariff(selectedTariffFound);
        setSavingsAnnual(proposal.savings_annual);
        setTotalEstimatedSaving(totalCost);
        setSelectedBattery(batteriesList[0]); // Show the first battery in the section 4 by default
        setValue(proposalValue);
        setIsFetching(false);
        setMonthsConsumptions(proposal.projectedConsumptions);
        setMcSettings({
          max: proposal.project.avgMonthlyConsumption * 2,
          maxLimit: proposal.project.avgMonthlyConsumption * 1.2,
          minLimit: proposal.project.avgMonthlyConsumption * 0.8,
        });
        setConsumptionsProfiles(profiles);

        
        const profileSelected = profiles.find(profile => profile.id === proposal.project.selectedConsumptionProfile )
        setProfileSelected(
          profileSelected ||
            profiles[0]
        );


        setMonthlySavings(proposal.monthlySavings);
      })
      .catch((error) => {
        setError(error.toString());
        setIsFetching(false);
      });
  };

  return [getProposal, getToken];
};

export default useProposal;
