import Section4 from "../Section4/Section4";
import styles from "./Batteries.module.css";
import ConsumptionProfileComponentL from "./ConsumptionProfile/ConsumptionProfileComponentL";
import SelfConsumption from "./SelfConsumption/SelfConsumption";
import Tariffs from "./Tariffs/Tariffs";

const Batteries = () => {
  return (
    <section className={styles.batteriesContainer}>
      <div className={styles.image}>
        <Tariffs/>
        <ConsumptionProfileComponentL />
        <SelfConsumption/>
      </div>
    </section>
  );
};

export default Batteries;
