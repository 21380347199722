import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { BatteriesContext } from "../context/BatteriesContext";
import { AddersContext } from "../context/AddersContext";
import { FinancialContext } from "../context/FinancialContext";
import { ProposalContext } from "../context/ProposalContext";

/* Custom Hook to create the object to send to the API */

const useProposalObject = () => {
  /* Global states */
  const { token } = useContext(AuthContext);
  const { proposalData, moduleId, inverterId, information } =
    useContext(ProposalContext);
  const {
    batteries,
    selectedBatteries,
    profileSelected,
    monthsConsumptions,
    selectedTariff,
  } = useContext(BatteriesContext);
  const { adders } = useContext(AddersContext);
  const { selectedPaymentOption } = useContext(FinancialContext);

  /* Create the object to send to the API */
  function createObject() {
    /* Settings part */
    const settings = {
      escalationRate: information.escalationRate,
      dcToAcRatio: information.dcToAcRatio,
      taxCredit: information.federalTaxCredit,
      applyDealerFee: true, // review
      selectedConsumptionProfile: profileSelected?.id,
    };

    /* Adders part */
    let addersToSend = JSON.parse(JSON.stringify(adders)).filter(
      (adder) => adder?.isChecked === true
    );
    addersToSend = addersToSend.map((adder) => {
      if (adder.isChecked) delete adder.isChecked;
      if (adder.measurementUnit) delete adder.totalPrice;
      return adder;
    });

    console.log("Baterias",batteries);


    /* Create the body */
    const body = {
      adders: addersToSend,
      batteries: batteries,
      financial: selectedPaymentOption
        ? { ...selectedPaymentOption, creditAmount: information.creditAmount }
        : null,
      settings: settings,
      overridePricePerWatt:
        information.overridePricePerWatt <= 2.4
          ? 2.4
          : information.overridePricePerWatt || null,
      panel: moduleId,
      inverter: inverterId,
      projectedConsumptions: monthsConsumptions,
      tariffId: selectedTariff?.id || null,
    };

    return body;
  }

  /* Add the proposalData to the body */
  function addApiData(body) {
    return {
      ...body,
      ...proposalData,
    };
  }

  /* Create the config to send to the API */
  function createConfig(body) {
    return {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json; charset=utf-8",
        "Cache-Control": "no-cache",
      },
      body: JSON.stringify(body),
    };
  }

  return { addApiData, createObject, createConfig };
};

export default useProposalObject;
