import { createContext, useState, useEffect} from "react";

/* URLS */
 export const rekonUrl = "https://rekon.demand.sentius.io";  // ReKon
 /* export const apiUrl = "https://057bslrz-4000.brs.devtunnels.ms";  */ // Backend Mauro local
      export const apiUrl = "https://api.sentius.io";    // Production
/*     export const apiUrl = "https://api.demand.sentius.io";  */ // Develop

const AppContext = createContext();
function AppProvider({ children }) {
  const [image, setImage] = useState("");
  const [shareModal, setShareModal] = useState(false);
  /* Boolean */
  const [videosLoadStatus, setVideosLoadStatus] = useState({
    fondo_001: false,
    fondo_002: false,
    fondo_004: false,
    fondo_006: false,
    fondo_007: false,
    newSolarCost: false,
    carbon: false,
    tree: false,
    gas: false,
  }); // Videos load status for optimization
  const [url, setUrl] = useState("");

  const [isUserActive, setIsUserActive] = useState(true);
  const [sending, setSending] = useState(false);
  const [applyChangesStatus, setApplyChangesStatus] = useState(null);
  const [autoSaveError, setAutoSaveError] = useState(false);
  const [sendSuccess, setSendSuccess] = useState(false);
  const [showWarning, setShowWarning] = useState("");
  const [showModalWarning, setShowModalWarning] = useState(false);

  const [inner, setInner] = useState({
    innerWidth: window.innerWidth,
    innerHeight: window.innerHeight,
  });



  useEffect(() => {
    function resize() {
      setInner({
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight,
      });
    }

    window.addEventListener("resize", resize);

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  /* Detect if the user is active or not -> save or not the proposal every 5sec */
  useEffect(() => {
    let inactivityTimeout;

    const resetInactivityTimeout = () => {
      clearTimeout(inactivityTimeout);
      inactivityTimeout = setTimeout(() => {
        setIsUserActive(false);
      }, 20000);
    };

    const handleUserActivity = () => {
      setIsUserActive(true);
      resetInactivityTimeout();
    };

    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);

    resetInactivityTimeout();

    return () => {
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
    };
  }, []);

  /* Function to handle the video load status */
  const handleVideoLoad = (id) => {
    setVideosLoadStatus((prev) => ({ ...prev, [id]: true }));
  };




  return (
    <AppContext.Provider
      value={{
        videosLoadStatus,
        handleVideoLoad,
        sendSuccess,
        setSendSuccess,
        sending,
        setSending,
        setApplyChangesStatus,
        applyChangesStatus,
        isUserActive,
        showWarning,
        setShowWarning,
        showModalWarning,
        setShowModalWarning,
        autoSaveError,
        setAutoSaveError,
        url,
        setUrl,
        inner,
        image,
        setImage,
        shareModal,
        setShareModal,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export { AppProvider, AppContext };
