import React, { useContext, useEffect, useState } from "react";
import styles from "./Section4.module.css";
import sourceImage from "../../assets/img/emphase5-1.webp";
import BatteryList from "./BatteryList";
import { BatteriesContext } from "../../context/BatteriesContext";
import { ProposalContext } from "../../context/ProposalContext";

const Section4 = ({reference}) => {
  /* Global states */
  const {
    value,
    setValue,
    totalEstimatedSaving,
    setTotalEstimatedSaving,
    onlyView,
  } = useContext(ProposalContext);
  const { selfConsumption, setSelfConsumption } = useContext(BatteriesContext);
  const {
    batteries,
    setBatteries,
    selectedBatteries,
    setSelectedBatteries,
    lastButtonPressed,
    setLastButtonPressed,
    selectedBatteryIndex,
    setSelectedBatteryIndex,
    selectedBattery,
    setSelectedBattery,
    noData,
    setBatteriesSettings,
  } = useContext(BatteriesContext);

  /* Component states */
  const [reset, setReset] = useState(false);

  /* Update the value when the user press the "+" , trash or "-" button */
  useEffect(() => {
    if (lastButtonPressed === "+") incrementValue();
    else if (lastButtonPressed === "-") decrementValue();
    else if (reset) resetValue();
  }, [selectedBatteryIndex, lastButtonPressed, reset]);

  /* Reset the value when the user press the trash button */
  const resetValue = () => {
    const newSelectedBatteries = [...selectedBatteries];
    /* Calculate the new total estimated saving */
    const calc =
      totalEstimatedSaving -
      batteries[selectedBatteryIndex].price *
        newSelectedBatteries[selectedBatteryIndex];
    /* Calculate the new value */
    const newValue =
      value -
      batteries[selectedBatteryIndex].price *
        newSelectedBatteries[selectedBatteryIndex];
    /* Reset the selected battery */
    newSelectedBatteries[selectedBatteryIndex] = 0;

    /* Update the states */
    setSelectedBatteries(newSelectedBatteries);
    setTotalEstimatedSaving(calc);
    setValue(newValue);
    updateBatteries(newSelectedBatteries);
    setReset(!reset);
  };

  /* Update the value when the user press the "+" button */
  const incrementValue = () => {
    function checkArray() {
      let count = 0; // Count to check if the user has selected any different battery

      /* iteration for check if the user has selected any different battery */
      for (let index = 0; index < selectedBatteries.length; index++) {
        if (index !== selectedBatteryIndex) {
          count += selectedBatteries[index];
        }
      }

      if (count > 0) return true;
      else return false;
    }

    if (selectedBatteries[selectedBatteryIndex] >= 0) {
      let newSelectedBatteries = [...selectedBatteries];
      if (checkArray()) {
        /* If count is may that 0, set Selected Batteries to 0 */
        newSelectedBatteries = newSelectedBatteries.map((value) => (value = 0));
      }
      newSelectedBatteries[selectedBatteryIndex] += 1;

      const newTotalEstimatedSaving =
        calculateTotalEstimatedCost(newSelectedBatteries);
      const updatedValue =
        value - totalEstimatedSaving + newTotalEstimatedSaving;

      setSelectedBatteries(newSelectedBatteries);
      setValue(updatedValue);
      setTotalEstimatedSaving(newTotalEstimatedSaving);

      setTimeout(() => setLastButtonPressed(null), 50);
      updateBatteries(newSelectedBatteries);
    }
  };

  /* Update the value when the user press the "-" button */
  const decrementValue = () => {
    const selectedCount = selectedBatteries.reduce(
      (count, value) => count + value,
      0
    );

    /* If the user has not selected any battery, return */
    if (selectedCount === 0) return;

    const newSelectedBatteries = [...selectedBatteries];
    const currentQuantity = newSelectedBatteries[selectedBatteryIndex];

    if (currentQuantity > 0) {
      newSelectedBatteries[selectedBatteryIndex] -= 1;
      setSelectedBatteries(newSelectedBatteries);

      const newTotalEstimatedSaving =
        calculateTotalEstimatedCost(newSelectedBatteries);

      const updatedValue =
        value - totalEstimatedSaving + newTotalEstimatedSaving;

      setTotalEstimatedSaving(newTotalEstimatedSaving);
      setValue(updatedValue);
      updateBatteries(newSelectedBatteries);

      setTimeout(() => setLastButtonPressed(null), 50);
    }
  };

  /* Change the selected battery */
  const changeBatteryType = (direction) => {
    let newIndex;
    if (direction === "left") {
      newIndex = selectedBatteryIndex - 1;
      if (newIndex < 0) {
        newIndex = batteries.length - 1;
      }
    } else if (direction === "right") {
      newIndex = selectedBatteryIndex + 1;
      if (newIndex > batteries.length - 1) {
        newIndex = 0;
      }
    }
    setSelectedBatteryIndex(newIndex);
    const newSelectedBattery = batteries[newIndex];
    setSelectedBattery(newSelectedBattery);
  };

  const calculateTotalEstimatedCost = (selectedBatteries) => {
    return selectedBatteries.reduce(
      (total, quantity, index) => total + quantity * batteries[index].price,
      0
    );
  };

  const updateBatteries = (newSelectedBatteries) => {
    setBatteries(
      batteries.map((battery, index) => ({
        ...battery,
        qty: newSelectedBatteries[index],
      }))
    );
  };

  return (
    <section id="section4" className={styles.section} ref={reference}>
      <div className={styles.containerSection}>
        <div className={styles.containerTitle}>
          <h3>Battery Options for Optimal Savings</h3>
        </div>
        {!onlyView && (
          <>
            <img
              className={styles.leftPosition}
              src="/icons/OpLeft.svg"
              alt="LeftButton"
              onClick={() => changeBatteryType("left")}
            />
            <img
              className={styles.rightPosition}
              src="/icons/OpRight.svg"
              alt="Right Button"
              onClick={() => changeBatteryType("right")}
            />
          </>
        )}

        <div className={styles.informationContainer}>
          <div className={styles.infoImageContainer}>
            <img src={selectedBattery.image || sourceImage} alt="Emphase" />
          </div>
          <div className={styles.information}>
            <h3 className={styles.informationTitle}>{selectedBattery.name}</h3>
            <p className={styles.info}>
              <span>Energy capacity: </span> {selectedBattery.capacity} kWh*
            </p>
            <p className={styles.info}>
              <span>Power rating: </span>3.84kW
            </p>
            <p className={styles.info}>
              <span>Manufacturer Warranty: </span>10 years
            </p>
            <p className={styles.info}>
              <span>Battery Backup Included </span>
            </p>
            <div className={styles.separate}></div>
            <div className={styles.IqBatteryContainer}>
              <p className={styles.informationTitle2}>Unit Price</p>
              <div className={styles.buttonsContainer}>
                <span className={styles.informationTitle2}>
                  ${selectedBattery.price}
                </span>
                {!onlyView && (
                  <button className={styles.buttonAdd} onClick={incrementValue}>
                    ADD
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles.selectedContainer} flex-column`}>
          <h3 className={styles.selectedTitle}>
            Selected Batter
            {selectedBatteries.reduce((b, acc) => b + acc, 0) === 1
              ? "y"
              : "ies"}
          </h3>
          <div className={styles.selected}>
            <div
              className={`d-flex flex-column justify-content-start ${styles.selectedBg}`}
            >
              <div
                className={`${styles.listTitles} d-flex justify-content-between`}
              >
                <p>Battery</p>
                <p>Unit Price</p>
                <p>Total Cost</p>
                <p>Quantity</p>
              </div>
              {selectedBatteries.map((quantity, index) => {
                if (!quantity) return null;

                return (
                  <BatteryList
                    key={index}
                    index={index}
                    quantity={quantity}
                    decrementValue={decrementValue}
                    incrementValue={incrementValue}
                    reset={reset}
                    setReset={setReset}
                  />
                );
              })}
            </div>
            <h3 className={styles.totalEstimatedTitle}>Total Estimated Cost</h3>
            <p className={styles.totalEstimatedValue}>
              ${totalEstimatedSaving}
            </p>
          </div>
        </div>
      </div>
      {/*       <Tariff/> */}
    </section>
  );
};

export default Section4;
