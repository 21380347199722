import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Document,
  Image,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View,
  pdf,
} from "@react-pdf/renderer";
import { ProposalContext } from "../../context/ProposalContext";
import { toPng } from "html-to-image";
import { AuthContext } from "../../context/AuthContext";
import { BatteriesContext } from "../../context/BatteriesContext";
import sourceImage from "../../assets/img/emphase5-1.png";
import BillsChart from "../Section3Reloaded/costLine.chart";
import ConsumptionProfileChart from "../Batteries/ConsumptionProfile/ConsumptionProfile.chart";
import { FinancialContext } from "../../context/FinancialContext";
import UtilityBillsChart from "../Section8/UtilityBills.chart";
import UtBillsEchartsComponents from "../Section6/UtBills.chart";
import HistoricalVsExpected from "../../components/Section7/HistoricalVsExpected.chart";

const styles = StyleSheet.create({
  //section 1
  customH1: {
    marginTop: "124px",
    fontSize: "35px",
    margin: 0,
    // fontFamily: "Lexend",
    fontWeight: 600,
    textAlign: "left",
    paddingLeft: "3%",
  },
  today: {
    color: "#ffb800",
  },
  background: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
  section1View: {
    height: 335,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    color: "white",
  },
  infoContainer: {
    marginTop: 20,
    marginLeft: "3%",
  },
  clientName: {
    fontSize: 20,
    fontWeight: "extrabold",
  },
  otherInfo: {
    fontSize: 7,
    marginTop: 7,
    display: "flex",
    flexDirection: "row",
    gap: 15,
  },
  splitedInfo: {
    gap: 2,
  },
  infoElement: {
    marginTop: 5,
  },

  //section2
  section2View: {
    width: "100%",
    height: 505,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  splitS2: {
    width: "47%",
    height: "80%",
    display: "flex",
  },
  left: {
    color: "#363636",
  },
  section2Title: {
    color: "#393941",
    fontSize: 30,
    letterSpacing: 2,
  },
  solarModelTitle: {
    color: "#393941",
    marginTop: 13,
    marginBottom: 5,
  },
  solarInfoTitle: {
    fontSize: 13,
    marginTop: 6,
  },
  solarInfoText: {
    fontSize: 8,
    marginTop: 2,
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  satImage: {
    width: "200px",
    height: "200px",
  },

  imageSatelite: {
    width: "200px",
    height: "200px",
  },

  section3View: {
    width: "100%",
    height: "100%",
  },

  /* Section 3 */
  section3Title: {
    textAlign: "center",
    marginTop: "32px",
    marginBottom: "32px",
    fontSize: "24px",
  },

  section3Paragraph: {
    textAlign: "justify",
    marginLeft: "32px",
    marginRight: "32px",
    fontSize: "12px",
  },

  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    marginTop: "16px",
    marginBottom: "16px",
  },

  billContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    rowGap: "8px",
    height: "80px",
    width: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.70)",
  },
  billValue: {
    color: "rgb(255, 0, 0)",
    fontSize: "18px",
  },
  billText: {
    color: "#fff",
    fontSize: "12px",
  },
  graphBackground: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    marginTop: "16px",
    marginBottom: "16px",
  },

  section6View: {
    width: "100%",
    height: "100%",
  },

  sectionTitleView: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100px",
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    marginTop: "32px",
    marginBottom: "32px",
  },

  section6Title: {
    fontSize: "24px",
    fontWeight: "extrabold",
    color: "#fff",
  },
  avgMonthlyBillText: {
    fontSize: "12px",
    color: "#fff",
  },
  avgMonthlyBill: {
    fontSize: "16px",
    color: "#fff",
  },
  avgView: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    width: "100%",
    height: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  section6FinancialsView: {
    width: "100%",
    height: "100%",
  },
  financialTitle: {
    fontSize: "24px",
    fontWeight: "extrabold",
    color: "#fff",
  },
  financialTitleContainer: {
    height: "100px",
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  financialSubTitle: {
    marginTop: "32px",
    marginLeft: "16px",
    fontSize: "16px",
    color: "#fff",
  },
  viewBackground: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
  selectedContainer: {
    marginTop: "16px",
    marginBottom: "16px",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    flexDirection: "column",
    rowGap: "16px",
  },
  selected: {
    backgroundColor: "#ffb800",
    width: "50%",
    height: "30px",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  selectedText: {
    color: "#fff",
    fontSize: "12px",
  },

  financialContainer: {
    display: "flex",
    flexDirection: "row",
    width: "50%",
    justifyContent: "space-between",
    marginTop: "8px",
    marginBottom: "8px",
  },
  financialText: {
    color: "#fff",
    fontSize: "12px",
  },
  term: {
    color: "#fff",
    fontSize: "8px",
  },
  incentivesTitle: {
    fontSize: "24px",
    color: "#fff",
  },
  taxCreditTitle: {
    color: "#fff",
    fontSize: "12px",
  },
  taxCreditValueContainer: {
    height: "40px",
    borderRadius: "10px",
    width: "50%",
    backgroundColor: "rgba(217, 217, 217, 0.17)",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  taxCreditValue: {
    color: "#ffb800",
  },
  backgroundImage: {
    height: 200,
    width: "100%",
  },
  section7View: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: 300,
  },
  section7ViewChart: {
    width: "100%",
    height: 541,
  },
  solarTitleContainer: {
    marginTop: "16px",
    marginBottom: "16px",
    height: "60px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#ffb800",
    fontSize: "24px",
    fontWeight: "extrabold",
  },
  solarContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginTop: "8px",
    marginBottom: "8px",
    justifyContent: "space-evenly",
    alignItems: "center",
    fontSize: "12px",
  },
  solarText: {
    textAlign: "center",
    width: "30%",
  },
  systemCost: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "50%",
    marginTop: "16px",
  },
  systemCostText: {
    fontSize: "16px",
    marginBottom: "8px",
  },
  systemCostFinancialAmount: {
    marginTop: "16px",
    width: "100%",
    backgroundColor: "#ffb800",
    borderRadius: "10px",
    height: "40px",
    color: "white",
    fontWeight: "semibold",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  section4View: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  batteriesView: {
    width: "90%",
    display: "flex",
    flexDirection: "row",
    color: "white",
    justifyContent: "center",
  },
  batteryImageContainer: {
    width: "50%",
  },
  batteryImage: {
    width: "90%",
  },
  batteryName: {
    fontSize: 30,
    marginBottom: 10,
  },
  batteryData: {
    fontSize: 13,
    width: "50%",
    display: "flex",
    justifyContent: "center",
    gap: 5,
    marginLeft: 17,
  },
  batteriesTotalContainer: {
    display: "flex",
    alignItems: "center",
  },
  batteriesTotal: {
    margin: "20 0 50 0",
    display: "flex",
    alignItems: "center",
    padding: "10 15",
    width: "40%",
    backgroundColor: "#ffb800",
    borderRadius: 20,
    color: "white",
    fontSize: 20,
  },
  graphBackground2: {
    backgroundColor: "rgba(0,0,0, 0.75)",
    color: "white",
    display: "flex",
    alignItems: "center",
  },
  section8View: {
    display: "flex",
    alignItems: "center",
    color: "white",
    backgroundColor: "black",
  },
  financialView: {
    width: "60%",
    backgroundColor: "rgba(182, 182, 182, 0.26)",
  },
  proposalDetails: {
    paddingBottom: 10,
  },
  monthlyPaymentTitle: {
    fontSize: 10,
    margin: "10 0 10 5",
  },
  paymentItem: {
    display: "flex",
    alignItems: "center",
    padding: "0 5%",
    gap: 10,
  },
  payment: {
    display: "flex",
    flexDirection: "row",
    fontSize: 8,
    justifyContent: "space-between",
  },
  paymentNumber: {
    fontSize: 15,
  },
  financialDetails: {
    backgroundColor: "rgba(182, 182, 182, 0.26)",
    padding: "20 0 30 0",
    fontSize: 7,
  },
  financialDetailsTitle: {
    paddingLeft: "2%",
  },
  financialBanner: {
    display: "flex",
    marginTop: 5,
  },
  financialBannerView: {
    display: "flex",
    height: 60,
  },
  financialImageView: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
  financialImage: {
    objectFit: "cover",
  },
  financialTextView: {
    width: "100%",
    height: "100%",
    backgroundColor: "#000000c0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  financialCompany: {
    fontSize: 20,
  },
  financialName: {
    marginTop: 7,
    fontSize: 15,
  },
  financialInfo: {
    margin: "10 0 0 0",
    padding: "0 2%",
    gap: 6,
  },
  financialInfoItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  financialRelevantInfo: {
    fontSize: 10,
    margin: "5 0 0 0",
  },
  financialNewCost: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "5%",
    fontSize: 12,
  },
  historicalContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  pageStyle: {
    backgroundColor: "black",
  },
  utilityBill: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "20 0 0 0",
  },
  utilityBillImage: {
    width: "100%",
  },
});

function ProposalPDFViewer() {
  /*   const { proposalData, moduleId, inverterId, information } =
    useContext(ProposalContext);
  const { selectedBatteries, batteries } = useContext(BatteriesContext);
  const { token } = useContext(AuthContext);
  const { selectedBank, selectedPaymentOption, taxCreditValue, taxCredit } =
    useContext(FinancialContext); */

  const consumptionProfile = useRef();
  const utilityBills = useRef();
  const billsChart = useRef();
  const HistoricalVsExpectedRef = useRef(null);
  const UtilityBillsRefChart = useRef(null);
  const satelitalImageRef = useRef(null);
  /*   const { profileSelected } = useContext(BatteriesContext); */
  const [battTotalCost, setBattTotalCost] = useState(0);

  const [consProfilePNG, setConsProfilePNG] = useState("");
  const [billsImage, setBillsImage] = useState("");
  const [utiBillPng, setUtiBillPng] = useState("");
  const [historical, setHistorical] = useState("");
  const [utillityBillsImage, setUtillityBillsImage] = useState("");
  const [satelitalImageSrc, setSatelitalImageSrc] = useState("");

  const [proposalDecoded, setProposalDecoded] = useState(null);

  useEffect(() => {
    const proposal = JSON.parse(localStorage.getItem("pdfInfo"));

    if (proposal) {

      setProposalDecoded(proposal);
      let totalCost = 0;

      proposal.selectedBatteries.map((qty, i) => {
        totalCost += qty * proposal.batteries[i].price;
      });
      setBattTotalCost(totalCost);
    }
  }, []);

  useEffect(() => {
    if (consumptionProfile.current) {

      const tempDiv = document.createElement("div");
      document.body.appendChild(tempDiv);
      tempDiv.appendChild(consumptionProfile?.current?.firstChild);
      toPng(tempDiv, { cacheBust: true }).then((dataUrl) => {
        setConsProfilePNG(dataUrl);
        document.body.removeChild(tempDiv);
      });
    }
  }, [consumptionProfile, proposalDecoded]);

  useEffect(() => {
    if (utilityBills.current) {

      const tempDiv2 = document.createElement("div");
      document.body.appendChild(tempDiv2);
      tempDiv2.appendChild(utilityBills.current.firstChild);
      toPng(tempDiv2, { cacheBust: true }).then((dataUrl) => {
        setUtiBillPng(dataUrl);
        document.body.removeChild(tempDiv2);
      });
    }
  }, [utilityBills, proposalDecoded]);

  useEffect(() => {
    if (billsChart.current) {

      const tempDiv3 = document.createElement("div");
      document.body.appendChild(tempDiv3);
      tempDiv3.appendChild(billsChart.current.firstChild);
      toPng(tempDiv3, { cacheBust: true }).then((dataUrl) => {
        setBillsImage(dataUrl);
        document.body.removeChild(tempDiv3);
      });
    }
  }, [billsChart, proposalDecoded]);

  useEffect(() => {
    if (HistoricalVsExpectedRef.current) {

      const tempDiv4 = document.createElement("div");
      document.body.appendChild(tempDiv4);
      tempDiv4.appendChild(HistoricalVsExpectedRef.current.firstChild);
      toPng(tempDiv4, { cacheBust: true }).then((dataUrl) => {
        setHistorical(dataUrl);
        document.body.removeChild(tempDiv4);
      });
    }
  }, [HistoricalVsExpectedRef, proposalDecoded]);

  useEffect(() => {
    if (UtilityBillsRefChart.current) {

      const tempDiv5 = document.createElement("div");
      document.body.appendChild(tempDiv5);
      tempDiv5.appendChild(UtilityBillsRefChart.current.firstChild);
      toPng(tempDiv5, { cacheBust: true }).then((dataUrl) => {
        setUtillityBillsImage(dataUrl);
        document.body.removeChild(tempDiv5);
      });
    }
  }, [UtilityBillsRefChart, proposalDecoded]);

  useEffect(() => {
    if (satelitalImageRef.current) {

      const tempDiv6 = document.createElement("div");
      document.body.appendChild(tempDiv6);
      tempDiv6.appendChild(satelitalImageRef.current.firstChild);
      toPng(tempDiv6, { cacheBust: true }).then((dataUrl) => {
        setSatelitalImageSrc(dataUrl);
        document.body.removeChild(tempDiv6);
      });
    }
  }, [satelitalImageRef, proposalDecoded]);

  return (
    <>
      {proposalDecoded && (
        <>
          {consProfilePNG &&
            utiBillPng &&
            historical &&
            /*             false && */
            utillityBillsImage &&
            satelitalImageSrc && (
              <>
                <PDFViewer style={{ width: "100vw", height: "100vh" }}>

                  <Document title="Contract Proposal">
                    <Page>
                      <View style={styles.section1View}>
                        <Image
                          src="/img/pdf/section1.png"
                          style={styles.background}
                        />
                        <View style={styles.titleContainer}>
                          <Text style={styles.customH1}>Experience</Text>
                          <Text style={styles.customH1}>Tomorrow's Energy</Text>
                          <Text style={[styles.customH1, styles.today]}>
                            TODAY
                          </Text>
                          <View style={styles.infoContainer}>
                            <Text style={styles.clientName}>
                              {proposalDecoded.proposalData?.project?.clientName.toUpperCase()}
                            </Text>
                            <View style={styles.otherInfo}>
                              <View style={styles.splitedInfo}>
                                <Text style={styles.infoElement}>
                                  {proposalDecoded.proposalData?.project?.clientAddress?.replaceAll(
                                    ",",
                                    ""
                                  )}
                                </Text>
                                <Text style={styles.infoElement}>
                                  {
                                    proposalDecoded.proposalData?.project
                                      ?.clientName
                                  }
                                </Text>
                                <Text style={styles.infoElement}>
                                  {
                                    proposalDecoded.proposalData?.project
                                      ?.clientEmail
                                  }
                                </Text>
                                <Text style={styles.infoElement}>
                                  {
                                    proposalDecoded.proposalData?.project
                                      ?.clientMobile
                                  }
                                </Text>
                              </View>
                              <View style={styles.splitedInfo}>
                                <Text style={styles.infoElement}>
                                  Your representative:
                                </Text>
                                <Text style={styles.infoElement}>
                                  {
                                    proposalDecoded.proposalData?.project
                                      ?.salesmanName
                                  }
                                </Text>
                                <Text style={styles.infoElement}>
                                  {
                                    proposalDecoded.proposalData?.project
                                      ?.salesmanMobile
                                  }
                                </Text>
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                      <View style={styles.section2View}>
                        <Image
                          src={"/img/pdf/section2.png"}
                          style={styles.background}
                        />
                        <View style={styles.splitS2}>
                          <View style={styles.left}>
                            <Text style={styles.section2Title}>
                              Customized Solar Solution
                            </Text>
                            <Text style={styles.solarModelTitle}>
                              Your Solar model
                            </Text>
                            <View>
                              <View>
                                <Text style={styles.solarInfoTitle}>
                                  {proposalDecoded.proposalData
                                    ?.realSystemCapacity ||
                                    proposalDecoded.proposalData
                                      ?.systemCapacity}
                                  kW
                                </Text>
                                <Text style={styles.solarInfoText}>
                                  System Size
                                </Text>

                                <Text style={styles.solarInfoTitle}>
                                  {
                                    proposalDecoded.proposalData.modules?.find(
                                      (module) =>
                                        module.id === proposalDecoded.moduleId
                                    )?.name
                                  }
                                </Text>
                                <Text style={styles.solarInfoText}>
                                  Selected Panel
                                </Text>

                                <Text style={styles.solarInfoTitle}>
                                  {
                                    proposalDecoded.proposalData.inverters.find(
                                      (inverter) =>
                                        inverter.id ===
                                        proposalDecoded.inverterId
                                    )?.name
                                  }
                                </Text>
                                <Text style={styles.solarInfoText}>
                                  Selected Inverter
                                </Text>

                                <Text style={styles.solarInfoTitle}>
                                  {
                                    proposalDecoded.proposalData.project
                                      .productionOffset
                                  }{" "}
                                  %
                                </Text>
                                <Text style={styles.solarInfoText}>
                                  Energy Offset
                                </Text>

                                <Text style={styles.solarInfoTitle}>
                                  {parseFloat(
                                    proposalDecoded.proposalData.ac_annual
                                  )?.toFixed(0)}{" "}
                                  kW
                                </Text>
                                <Text style={styles.solarInfoText}>
                                  Yearly Energy Produced
                                </Text>
                              </View>
                            </View>
                          </View>
                        </View>
                        <View style={styles.splitS2}>
                          <View style={styles.imageContainer}>
                            <View style={styles.satImage}>
                              <Image
                                src={satelitalImageSrc}
                                style={styles.imageSatelite}
                              />
                            </View>
                          </View>
                        </View>
                      </View>
                      <View style={styles.section3View}>
                        <Image
                          src={"/img/pdf/fondo-col1.png"}
                          style={styles.background}
                        />
                        <Text style={styles.section3Title}>
                          Conventional Enery Costs
                        </Text>
                        <Text style={styles.section3Paragraph}>
                          Electricity costs rise{" "}
                          {proposalDecoded.information?.escalationRate}% yearly
                          due to inflation. Solar investment is wise—predictable
                          costs, shielding from rate hikes, leading to big
                          savings.
                        </Text>
                        <Image
                          src={billsImage}
                          style={styles.graphBackground}
                        />
                        <View style={styles.container}>
                          <View style={styles.billContainer}>
                            <Text style={styles.billValue}>
                              {proposalDecoded.proposalData?.project?.avgMonthlyBill?.toFixed(
                                2
                              )}
                            </Text>
                            <Text style={styles.billText}>
                              Your utility bill today
                            </Text>
                          </View>
                          <View style={styles.billContainer}>
                            <Text style={styles.billValue}>
                              {proposalDecoded.proposalData
                                ?.thirtyYearsBillProjection
                                ? proposalDecoded.proposalData
                                    ?.thirtyYearsBillProjection[
                                    proposalDecoded.proposalData
                                      ?.thirtyYearsBillProjection.length - 1
                                  ]
                                : 0}
                            </Text>
                            <Text style={styles.billText}>
                              Your utility bill over 30 years
                            </Text>
                          </View>
                        </View>
                        <View style={styles.billContainer}>
                          <Text style={styles.billValue}>
                            {proposalDecoded.proposalData?.billOverThirtyYears?.toFixed(
                              2
                            )}
                          </Text>
                          <Text style={styles.billText}>
                            Your total bill over 30 years
                          </Text>
                        </View>
                      </View>
                      {/* Baterias */}
                      <View style={styles.section4View}>
                        <Image
                          style={styles.background}
                          src={"/img/pdf/section4.png"}
                        />
                        {proposalDecoded.selectedBatteries.map((qty, i) => {
                          if (qty > 0) {
                            return (
                              <View key={i} style={styles.batteriesView}>
                                <View style={styles.batteryImageContainer}>
                                  <Image
                                    src={
                                      proposalDecoded.batteries[i].image ||
                                      sourceImage
                                    }
                                    style={styles.batteryImage}
                                  />
                                </View>
                                <View style={styles.batteryData}>
                                  <Text style={styles.batteryName}>
                                    {proposalDecoded.batteries[i]?.name}
                                  </Text>

                                  <Text>
                                    Energy Capacity:{" "}
                                    {proposalDecoded.batteries[i].capacity} KWh*
                                  </Text>
                                  <Text>Power Rating: 3.84 KW</Text>
                                  <Text>Manufacturer Warranty: 10 years</Text>
                                  <Text>Battery Backup Included</Text>
                                  <Text>
                                    Unit Price:{" "}
                                    {proposalDecoded.batteries[i].price}
                                  </Text>
                                  <Text>Quantity: {qty}</Text>
                                </View>
                              </View>
                            );
                          } else return null;
                        })}
                        <View style={styles.batteriesTotalContainer}>
                          <View style={styles.batteriesTotal}>
                            <Text>{`Total Cost: $${battTotalCost}`}</Text>
                          </View>
                        </View>
                        <View style={styles.graphBackground2}>
                          <Text>Consumption Profile</Text>
                          <Text>"{proposalDecoded.profileSelected?.name}"</Text>
                          <Image src={consProfilePNG} />
                        </View>
                      </View>
                      {/* SAVE WITH SOLAR */}
                      <View style={styles.section6View}>
                        <Image
                          src="/img/pdf/fondo-006.png"
                          style={styles.background}
                        />
                        <View style={styles.sectionTitleView}>
                          <Text style={styles.section6Title}>
                            Save with Solar
                          </Text>
                        </View>
                        {/* <Image src={utillityBillsImage} style={styles.graphBackground} /> */}

                        <Image
                          src={utiBillPng}
                          style={styles.graphBackground}
                        />

                        <View style={styles.avgView}>
                          <Text style={styles.avgMonthlyBill}>
                            {proposalDecoded.proposalData.project.avgMonthlyBill?.toFixed(
                              2
                            )}
                          </Text>
                          <Text style={styles.avgMonthlyBillText}>
                            Your Current Utility Bill
                          </Text>
                        </View>
                      </View>
                    </Page>
                    <Page>
                      <View style={styles.section6FinancialsView}>
                        <Image
                          src="/img/pdf/fondo-006.png"
                          style={styles.background}
                        />
                        <View style={styles.sectionTitleView}>
                          <Text style={styles.financialTitle}>
                            Financial Options
                          </Text>
                        </View>
                        <View style={styles.viewBackground}>
                          <Text style={styles.financialSubTitle}>
                            Selected financial option:{" "}
                          </Text>
                          <View style={styles.selectedContainer}>
                            <View style={styles.selected}>
                              <Text style={styles.selectedText}>
                                {proposalDecoded.selectedBank}
                              </Text>
                            </View>
                            <View style={styles.selected}>
                              <Text style={styles.selectedText}>
                                {proposalDecoded.selectedPaymentOption.name}
                              </Text>
                            </View>
                            <View style={styles.financialContainer}>
                              <Text style={styles.financialText}>
                                Dealer Fee
                              </Text>
                              <Text style={styles.financialText}>
                                {proposalDecoded.selectedPaymentOption
                                  .dealerFee || "-"}
                              </Text>
                            </View>
                            <View style={styles.financialContainer}>
                              <Text style={styles.financialText}>
                                Interest Rate
                              </Text>
                              <Text style={styles.financialText}>
                                {proposalDecoded.selectedPaymentOption
                                  .interestRate || "-"}
                              </Text>
                            </View>
                            <Text style={styles.term}>
                              Final terms will be provided in your loan
                              agreement.
                            </Text>
                            <Text style={styles.incentivesTitle}>
                              My Incentives
                            </Text>
                            <Text style={styles.taxCreditTitle}>
                              Federal Tax Credit
                            </Text>
                            <View style={styles.taxCreditValueContainer}>
                              <Text style={styles.taxCreditValue}>
                                ${proposalDecoded.taxCreditValue?.toFixed(2)}
                              </Text>
                            </View>
                            <Text style={styles.term}>
                              Apply credit to my loan
                            </Text>
                            <View style={styles.financialContainer}>
                              <Text style={styles.financialText}>
                                Federal tax Credit:
                              </Text>
                              <Text style={styles.financialText}>
                                {proposalDecoded.taxCredit ? "Yes" : "No"}
                              </Text>
                            </View>
                            <View style={styles.financialContainer}>
                              <Text style={styles.financialText}>
                                Credit Amount:
                              </Text>
                              <Text style={styles.financialText}>
                                {proposalDecoded.taxCredit
                                  ? `${proposalDecoded.information.creditAmount}%`
                                  : "-"}
                              </Text>
                            </View>
                          </View>
                        </View>
                      </View>
                    </Page>
                    <Page style={styles.pageStyle}>
                      <View style={styles.section7View}>
                        <Image
                          src="/img/pdf/newsolarcost000.png"
                          style={styles.background}
                        />
                        <View style={styles.solarTitleContainer}>
                          <Text>Next Solar Cost</Text>
                        </View>
                        <View style={styles.solarContainer}>
                          <Text style={styles.solarText}>Base Price</Text>
                          <Text style={styles.solarText}>
                            ${proposalDecoded.proposalData?.baseFinalPrice}
                          </Text>
                        </View>
                        <View style={styles.solarContainer}>
                          <Text style={styles.solarText}>
                            Federal Tax Credit
                          </Text>
                          <Text style={styles.solarText}>
                            ${proposalDecoded.proposalData?.baseFederalTax}
                          </Text>
                        </View>
                        <View style={styles.systemCost}>
                          <Text style={styles.systemCostText}>
                            Total System Cost
                          </Text>
                          <View style={styles.systemCostFinancialAmount}>
                            <Text>
                              ${proposalDecoded.proposalData?.financialAmount}
                            </Text>
                          </View>
                        </View>
                      </View>
                      <View style={styles.section7ViewChart}>
                        <Image
                          src="/img/pdf/fondo-007.png"
                          style={styles.background}
                        />
                        <View style={styles.historicalContainer}>
                          <Image src={historical} />
                        </View>
                      </View>

                      {/* <Page size="A4"> */}
                      <View>
                        <View style={styles.section8View}>
                          <View style={styles.financialView}>
                            <View style={styles.proposalDetails}>
                              <View style={styles.monthlyPaymentTitle}>
                                <Text>MONTHLY PAYMENT</Text>
                              </View>
                              <View style={styles.payment}>
                                <View style={styles.paymentItem}>
                                  <Text>Payment 1-15</Text>
                                  <Text style={styles.paymentNumber}>
                                    ${proposalDecoded.proposalData.payment}
                                  </Text>
                                </View>
                                <View style={styles.paymentItem}>
                                  <Text>Estimated 30 years saving</Text>
                                  <Text style={styles.paymentNumber}>
                                    $
                                    {
                                      proposalDecoded.proposalData
                                        ?.savingsOverThirtyYears
                                    }
                                  </Text>
                                </View>
                              </View>
                            </View>
                            <View style={styles.financialDetails}>
                              <Text style={styles.financialDetailsTitle}>
                                FINANCE OPTION
                              </Text>
                              <View style={styles.financialBanner}>
                                <View style={styles.financialBannerView}>
                                  <View style={styles.financialImageView}>
                                    <Image
                                      style={styles.financialImage}
                                      src="/img/pdf/financial.png"
                                    />
                                  </View>
                                  <View style={styles.financialTextView}>
                                    <Text style={styles.financialCompany}>
                                      {proposalDecoded.selectedPaymentOption
                                        .bankName || "-"}
                                    </Text>
                                    <Text style={styles.financialName}>
                                      {proposalDecoded.selectedPaymentOption
                                        .name ||
                                        "Financial company was not selected"}
                                    </Text>
                                  </View>
                                </View>
                              </View>

                              <View style={styles.financialInfo}>
                                <View style={styles.financialInfoItem}>
                                  <Text>Loan Term</Text>
                                  <Text>
                                    {Math.round(
                                      proposalDecoded.selectedPaymentOption
                                        .term / 12
                                    ) || "-"}{" "}
                                    years
                                  </Text>
                                </View>
                                <View style={styles.financialInfoItem}>
                                  <Text>Loan Term</Text>
                                  <Text>
                                    {
                                      proposalDecoded.selectedPaymentOption
                                        .interestRate
                                    }
                                    %
                                  </Text>
                                </View>
                                <View
                                  style={[
                                    styles.financialRelevantInfo,
                                    styles.financialInfoItem,
                                  ]}
                                >
                                  <Text>TOTAL INCENTIVES</Text>
                                  <Text>
                                    $
                                    {proposalDecoded.taxCreditValue?.toFixed(2)}
                                  </Text>
                                </View>
                                <View
                                  style={[
                                    styles.financialRelevantInfo,
                                    styles.financialInfoItem,
                                  ]}
                                >
                                  <Text>FINANCED AMOUNT</Text>
                                  <Text>
                                    $
                                    {proposalDecoded.proposalData?.financialAmount?.toFixed(
                                      2
                                    )}
                                  </Text>
                                </View>
                              </View>
                            </View>
                            <View style={styles.financialNewCost}>
                              <Text>NEW COST</Text>
                              <Text>
                                $
                                {proposalDecoded.proposalData.financialAmount?.toFixed(
                                  2
                                )}
                              </Text>
                            </View>
                          </View>
                        </View>
                      </View>
                      <View style={styles.utilityBill}>
                        <Image
                          style={styles.utilityBillImage}
                          src={utillityBillsImage}
                        />
                      </View>
                      {/* </Page> */}
                    </Page>
                  </Document>
                </PDFViewer>
              </>
            )}
          {(!consProfilePNG ||
            !utiBillPng ||
            !historical ||
            !utillityBillsImage ||
            !satelitalImageSrc) && (
            <>
              <div
                ref={consumptionProfile}
                style={{ width: "100%", height: "48vw" }}
              >
                <ConsumptionProfileChart
                  profileSelected={proposalDecoded.profileSelected}
                />
              </div>
              <div ref={utilityBills} style={{ width: "80%", height: "80vw" }}>
                <UtBillsEchartsComponents
                  proposalData={proposalDecoded.proposalData}
                  setSavingsAnnual={false}
                  setSavingsAnnualDataIndex={false}
                />
              </div>
              <div ref={billsChart} style={{ width: "100%", height: "60vw" }}>
                <BillsChart
                  height={"100%"}
                  proposalData={proposalDecoded.proposalData}
                />
              </div>
              <div
                ref={HistoricalVsExpectedRef}
                style={{ width: "100%", height: "60vw" }}
              >
                <HistoricalVsExpected
                  proposalData={proposalDecoded.proposalData}
                  monthsConsumptions={proposalDecoded.monthsConsumptions}
                />
              </div>
              <div
                ref={UtilityBillsRefChart}
                style={{
                  width: "100%",
                  height: "0vh",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <UtilityBillsChart
                  proposalData={proposalDecoded.proposalData}
                />
              </div>
              <div ref={satelitalImageRef}>
                <img
                  src={
                    proposalDecoded?.proposalData?.image ||
                    proposalDecoded?.proposalData?.satelitalImage
                  }
                  alt="Satelital"
                />
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}

export default ProposalPDFViewer;
