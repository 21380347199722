import { useContext, useEffect, useState } from "react";
import { AppContext, apiUrl } from "../context/AppContext";
import useProposalObject from "./useProposalObject";
import { AuthContext } from "../context/AuthContext";
import { BatteriesContext } from "../context/BatteriesContext";
import { AddersContext } from "../context/AddersContext";
import { FinancialContext } from "../context/FinancialContext";
import { ProposalContext } from "../context/ProposalContext";

/* Custom hook to send and get the calcs to refresh the proposal */
const useDataToCalculate = () => {
  /* Global states */
  const { setApplyChangesStatus } = useContext(AppContext);
  const { token } = useContext(AuthContext);
  const { proposalData, moduleId, inverterId, information, isFetching, onlyView} =
    useContext(ProposalContext);
  const { adders } = useContext(AddersContext);
  const {
    batteries,
    selectedBatteries,
    profileSelected,
    setMonthlySavings,
    mcSettings,
    monthlySavings,
  } = useContext(BatteriesContext);
  const { selectedPaymentOption } =
    useContext(FinancialContext);

  /* Custom Hook */
  const { createConfig } = useProposalObject();

  /*States of the hook */
  const [config, setConfig] = useState(null);
  const [isFirst, setIsFirst] = useState(0);

  /* Create the object to send to the API when the dependencies change and the timeout is done */
  useEffect(() => {
    let createObjectTimeOut;
    clearTimeout(createObjectTimeOut);

    createObjectTimeOut = setTimeout(() => {
      if (!isFetching && token && proposalData) {
        if (isFirst === 0) {
          setIsFirst(1);
          return;
        }
        setApplyChangesStatus("Applying Changes...");
        const {
          ac_monthly,
          project,
          newBillsProjection,
          systemCapacity,
          realSystemCapacity,
        } = proposalData;

        let addersToSend = adders.filter((a) => a.isChecked);


        const object = {
          proposal: {
            ac_monthly,
            project: {
              perKWHSellingPrice: project.perKWHSellingPrice,
              avgMonthlyConsumption: project.avgMonthlyConsumption,
              avgMonthlyBill: project.avgMonthlyBill,
              perKWHPrice: project.perKWHPrice,
              basePricePerWatt: project.basePricePerWatt,
              overridePricePerWatt: information.overridePricePerWatt,
              escalationRate: information.escalationRate,
              taxCredit: information.federalTaxCredit,
              creditAmount: information.creditAmount,
              applyDealerFee: true, // review
              projectedConsumptions: project.projectedConsumptions,
              solarPanel: project.solarPanel,
            },
            newBillsProjection,
            systemCapacity,
            realSystemCapacity,
          },
          adders: addersToSend,
          financial: selectedPaymentOption,
          batteries: batteries,
          consumptionProfile: profileSelected?.consumptionPercentage,
          modules: proposalData.modules.find((panel) => panel.id === moduleId ),
        };

        const config = createConfig(object);

        setConfig(config);
      }
    }, 1000);

    return () => {
      clearTimeout(createObjectTimeOut);
    };
  }, [
    isFetching,
    information,
    batteries,
    adders,
    selectedPaymentOption,
    moduleId,
    inverterId,
    monthlySavings,
    mcSettings,
  ]);


/*   }, [batteries, profileSelected]); */

  const getCalcs = async (config, endpoint) => {
    try {
      let response = await fetch(`${apiUrl}/${endpoint}`, config);
      let calcs = await response.json();
      console.log("Calculos",calcs);
      return calcs;
    } catch (error) {
      /* Manejar Error aquí */
      console.log(error);
    }
  };

  return [config, getCalcs];
};

export default useDataToCalculate;
