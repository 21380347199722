import { useContext } from "react";
import styleslider from "../Section4/SelfConsumption/SelfConsumption.module.css"
import { FinancialContext } from "../../context/FinancialContext";
import { ProposalContext } from "../../context/ProposalContext";

const Sec6RangeContainer = () => {
  const { information, setInformation,proposalData, onlyView } =
    useContext(ProposalContext);

  const {setTaxCreditValue} = useContext(FinancialContext)

  const handleChange = (event) => {
    const valueInput = parseInt(event.target.value);
    const name = event.target.name;


    let valueTc = proposalData.baseFinalPrice * (information.federalTaxCredit / 100 );
    let newValueIncentive = valueTc * (valueInput / 100);
    console.log("Nuevo Valor",newValueIncentive);
    setTaxCreditValue(newValueIncentive);
    setInformation({ ...information, [name]: valueInput });
  };

  return (
    <div className={styleslider.rangeContainer}>
      <span
        className={`${styleslider.rangeValue} ${styleslider.incentivesSpan}`}
      >
        {information.creditAmount}%
      </span>
      <input
        onChange={(event) => {
          handleChange(event);
        }}
        disabled={onlyView}
        className={`${styleslider.customRange} ${styleslider.incentivesRange}`}
        value={information.creditAmount}
        type="range"
        id="creditAmount"
        name="creditAmount"
        min={0}
        max={100}
      />
    </div>
  );
};

export default Sec6RangeContainer;
