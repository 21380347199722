import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Proposal from "./Proposal";
import "./App.css";
import "./styles.css";
import { useContext, useEffect } from "react";
import SaveProposalStatus from "./components/SaveProposalStatus/SaveProposalStatus";
import { jwtDecode } from "jwt-decode";
import Page404 from "./components/Page404/Page404";
import Error from "./components/Error/Error";
import { ProposalContext } from "./context/ProposalContext";
import { AppContext } from "./context/AppContext";
import { AuthContext } from "./context/AuthContext";
import ProposalPDFViewer from "./components/ProposalPDF/ProposalPDFViewer";

function App() {
  /* Global states */
  const { error, saveSuccess, isFetching } = useContext(ProposalContext);
  const { setUrl } = useContext(AppContext);
  const { setToken } = useContext(AuthContext);

  /* Hooks */
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  useEffect(() => {
    const currentURL = window.location.href;
    setUrl(currentURL);
  }, []);

  /* Check if the url has a token and redirect to the proposal */
  useEffect(() => {
    if (pathname === "/project") {
      const searchParams = new URLSearchParams(search);
      const token = searchParams.get("token");
      if (token) {
        const tokenData = jwtDecode(token);
        console.log(tokenData.id);
        setToken(token);
        navigate(`/project?token=${token}`);
      }
    }
  }, [navigate, pathname]);

  /* Check if the proposal has an error and redirect to the error page */
  useEffect(() => {
    if (error) navigate("/err");
  }, [error]);

  return (
    <div className="App">
      <Routes>
        <Route path="/err" element={<Error error={error} />} />
        <Route path="*" element={<Navigate to="/404" />} />
        <Route path="/404" element={<Page404 />} />
        <Route path="/project" element={<Proposal />} />
        <Route path="/proposal/:client_id" element={<Proposal />} />
        <Route path={`/project/proposal/pdf`} element={<ProposalPDFViewer />} />
      </Routes>
      {saveSuccess && <SaveProposalStatus />}
    </div>
  );
}

export default App;
